<template>
  <div>
    <div class="mb-xl-margin-file-sign pl-8 pr-8">
      <v-row class="mt-10">
        <v-col class="text-left" cols="6">
          <h1 class="report-voluntary__title">Renuncias voluntarias</h1>
        </v-col>
      </v-row>

      <v-card class="report-voluntary__card px-8 mt-8" elevation="0" outlined>
        <!-- Filtros -->
        <v-row>
          <v-col cols="3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dateR"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  clearable
                  v-model="dateR"
                  label="Fecha"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#afb0b0"> mdi-calendar </v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker range v-model="dateR" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(dateR)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              :items="listProfile"
              label="Cargo"
              v-model="profileId"
              item-text="name"
              item-value="id"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              :items="listStatus"
              label="Estado de renuncia"
              v-model="statusId"
              item-text="name"
              item-value="id"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="search" label="Buscar">
              <template v-slot:prepend-inner>
                <v-icon color="#afb0b0"> mdi-magnify </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Tabla -->
        <v-row>
          <v-col cols="12">
            <v-data-table
              dense
              :search="search"
              :loading="loading.table"
              :items-per-page="15"
              style="background-color: #f9f9f900"
              :headers="headers"
              :items="items"
              item-key="name"
              class="elevation-0 report-voluntary__table"
              :footer-props="{ itemsPerPageOptions: [10, 15, 30] }"
            >
              <!-- Sin resultados de busqueda -->
              <template v-slot:no-results>
                <v-alert
                  icon="mdi-information-outline"
                  prominent
                  text
                  type="info"
                >
                  <b>No se encontraron resultados</b>
                </v-alert>
              </template>
              <!-- Sin Data -->
              <template class="pl-0 pr-0" v-slot:no-data>
                <v-alert
                  icon="mdi-information-outline"
                  prominent
                  text
                  type="info"
                >
                  <b>No se encontró información.</b>
                </v-alert>
              </template>
              <!-- Loading -->
              <template v-slot:progress>
                <v-progress-linear
                  color="#214484"
                  :height="5"
                  indeterminate
                  stream
                ></v-progress-linear>
              </template>
              <template v-slot:item.user_img="{ item }">
                <div class="mt-3">
                  <img
                    style="border-radius: 120px; border: 1.3px solid #466be3"
                    width="40"
                    height="40"
                    :src="item.user_img"
                  />
                </div>
              </template>
              <!-- Nombre -->
              <template v-slot:item.user_full_name="{ item }">
                <div>
                  <p class="mb-0 mt-2 text-1-1-lines">
                    <strong> {{ item.user_full_name }} </strong>
                  </p>
                  <p class="mb-0 mb-2 text-1-1-lines">
                    {{ item.user_document_number }}
                  </p>
                </div>
              </template>
              <!-- Estado -->
              <template v-slot:item.resign_status_name="{ item }">
                <v-chip
                  v-if="item.resign_status_name"
                  :color="getColorStatus(item.resign_status_name)"
                  dark
                  small
                >
                  <strong> {{ item.resign_status_name }} </strong>
                </v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn @click="getInfo(item)" small fab icon>
                  <v-icon color="#898989"> mdi-magnify </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      @close="message.dialog = false"
    >
    </Alert>
    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>

    <v-dialog v-model="modal" max-width="800">
      <v-card max-width="900">
        <v-card-title>
          <p class="report-voluntary__title">Detalle de la renuncia</p>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <p class="report-voluntary__txt_modal text-left mb-2">Usuario</p>

              <v-row>
                <v-col cols="2">
                  <img
                    style="border-radius: 100px; border: 1px solid #466be3"
                    width="50"
                    height="50"
                    :src="info.user_img"
                  />
                </v-col>
                <v-col cols="10">
                  <p class="text-left mb-0 mt-1">
                    {{ info.user_full_name }}
                  </p>
                  <p class="text-left mb-0">{{ info.user_document_number }}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="text-left pt-3 pb-1">
              <p class="report-voluntary__txt_modal text-left mb-2">
                Estado de la renuncia
              </p>
              <v-chip
                class="mt-3"
                :color="getColorStatus(info.resign_status_name)"
                dark
                small
              >
                {{ toTitleCase(info.resign_status_name) }}
              </v-chip>
            </v-col>
            <v-col cols="6" class="text-left pt-0 pb-1">
              <p class="report-voluntary__txt_modal text-left mb-2">
                Centro de costos
              </p>
              <p>{{ toTitleCase(info.user_cost_center) }}</p>
            </v-col>
            <v-col cols="6" class="text-left pt-0 pb-1">
              <p class="report-voluntary__txt_modal text-left mb-2">Cargo</p>
              <p>{{ toTitleCase(info.user_profile_name) }}</p>
            </v-col>
            <v-col cols="6" class="text-left pt-0 pb-1">
              <p class="report-voluntary__txt_modal text-left mb-2">
                Fecha de solicitud de renuncia
              </p>
              <p>
                {{ momentFormat(info.resignation_request_date) }}
              </p>
            </v-col>
            <v-col cols="6" class="text-left pt-0 pb-1">
              <p class="report-voluntary__txt_modal text-left mb-2">
                Subcentro de costos
              </p>
              <p>{{ info.user_sub_cost_center }}</p>
            </v-col>
            <v-col cols="8">
              <p class="report-voluntary__txt_modal text-left mb-0">
                Encuesta de retiro
              </p>
            </v-col>
            <v-col cols="4">
              <v-btn @click="openLetter" text color="#466BE3">
                <strong> <u> VER CARTA DE RENUNCIA </u> </strong>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-layout justify-center>
              <v-progress-circular
                v-if="loadingForm"
                :size="60"
                color="#466BE3"
                indeterminate
              ></v-progress-circular>
            </v-layout>
            <template v-for="data in listAnswer">
              <v-col :key="data.answer" style="text-align: initial" cols="12">
                <p
                  :class="
                    data.question
                      ? 'report-voluntary__txt_modal_small mb-1'
                      : 'hidden'
                  "
                >
                  {{ data.question }}
                </p>
                <template v-if="Array.isArray(data.answers)">
                  <br :key="'a' + data.answer" />
                  <br :key="'a1' + data.answer" />
                  <template v-for="data1 in data.answers">
                    <p :key="data1" class="mb-0">
                      <v-icon color="#466be3"> mdi-circle-medium </v-icon>
                      {{ data1 }}
                    </p>
                    <br :key="'o' + data1" />
                  </template>
                </template>

                <template v-else>
                  <p class="mb-0">
                    <template
                      v-if="
                        data.answers == null || !data.answers.startsWith('http')
                      "
                    >
                      {{ data.answers }}
                    </template>
                    <template v-else>
                      Haz click
                      <a @click="(dialog = true), (documentUrl = data.answers)"
                        ><b>aquí</b></a
                      >
                      para visualizar el archivo
                    </template>
                  </p>
                </template>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-row>
            <v-col cols="6">
              <v-btn
                :loading="loadingAll"
                @click="updateStatus(9)"
                rounded
                block
                color="#466BE3"
                outlined
              >
                Cancelar renuncia
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                :loading="loadingAll"
                @click="updateStatus(4)"
                rounded
                block
                color="#466BE3"
                dark
                class="report-retirement__button"
              >
                Aceptar renuncia
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import color from "@/mixins/color";
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
import moment from "moment";
import { TYPEFORM } from "@/utils/util.js";
export default {
  components: {
    Alert,
  },
  mixins: [color],
  data() {
    return {
      typeForm: TYPEFORM,
      modal: false,
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
      loading: {
        table: false,
      },
      items: [],
      headers: [
        { text: "", value: "user_img", class: "report-voluntary__header" },
        {
          text: "Usuario",
          value: "user_full_name",
          class: "report-voluntary__header",
        },
        {
          text: "Cargo",
          value: "user_profile_name",
          class: "report-voluntary__header",
        },
        {
          text: "Cliente",
          value: "user_client",
          class: "report-voluntary__header",
        },
        {
          text: "Campaña",
          value: "user_campaign",
          class: "report-voluntary__header",
        },
        {
          text: "Fecha de solicitud",
          value: "resignation_request_date",
          class: "report-voluntary__header",
        },
        {
          text: "Estado",
          value: "resign_status_name",
          class: "report-voluntary__header",
        },
        {
          text: "Acciones",
          value: "actions",
          class: "report-voluntary__header",
        },
      ],
      listStatus: [
        {
          id: 5,
          name: "Pendiente",
        },
        {
          id: 4,
          name: "Completado",
        },
        {
          id: 3,
          name: "Expirado",
        },
        {
          id: 6,
          name: "Error",
        },
        {
          id: 8,
          name: "En proceso",
        },
        {
          id: 9,
          name: "Anulado",
        },
      ],
      listProfile: [],
      loadingAll: false,
      loadingForm: false,
      token: "",
      infoToken: "",
      name: "",
      statusId: "",
      profileId: "",
      dateR: [],
      search: "",
      menu: false,
      startDate: "",
      endDate: "",
      show1: [],
      listAnswer: [],
      info: {
        document_sign_users_id: null,
        document_sign_users: "",
        user_name: "",
        user_lastname: "",
        user_full_name: " ",
        user_document_number: "",
        user_profile_name: "",
        user_client: "",
        user_campaign: "",
        user_cost_center: "",
        user_sub_cost_center: "",
        user_img: "",
        resign_observations: null,
        resign_status_name: "",
        resignation_request_date: "",
        resignation_update_date: "",
      },
    };
  },
  watch: {
    statusId: function (n) {
      this.getReport();
    },
    profileId: function (n) {
      this.getReport();
    },
    dateR: function (n) {
      if (n == null) {
        this.startDate = "";
        this.endDate = "";
        this.getReport();
      } else if (n.length == 2) {
        this.startDate = this.dateR[0];
        (this.endDate = this.dateR[1]), this.getReport();
      }
    },
  },
  computed: {},
  methods: {
    openLetter() {
      window.open(this.info.document_sign_users, "_blank");
    },
    getForms() {
      this.loadingForm = true;
      Api.noAuth()
        .getFormsQuestions(70)
        .then((resp) => resp.json())
        .then((data) => {
          this.loadingForm = false;
          // console.log(data);
          this.show1 = data.data[0].questions;
          this.title = data.data[0].title;
          this.getFormsAnswer();
        })
        .catch((error) => {
          this.loadingForm = false;
          console.log(error);
        });
    },
    getFormsAnswer() {
      this.loadingForm = true;
      Api.noAuth()
        .getFormsAnswerId(this.info.user_document_number, 70)
        .then((resp) => resp.json())
        .then((data) => {
          this.loadingForm = false;
          let lastIndex = data.data.length - 1;
          this.answers = data.data[lastIndex].answers;
          // this.answers = data.data[0].answers;
          var r = [];
          for (let i = 0; i < this.show1.length; i++) {
            if (
              this.show1[i].type != TYPEFORM.HTML &&
              this.show1[i].type != TYPEFORM.SUBMIT &&
              this.show1[i].type != TYPEFORM.SECTION_BREAK &&
              this.show1[i].type != TYPEFORM.HIDDEN
            ) {
              if (this.answers[this.show1[i].name]) {
                r.push({
                  question: this.show1[i].question_label,
                  answers:
                    this.answers[this.show1[i].name].length === 0
                      ? this.answers[this.show1[i].name + "_url"]
                      : this.answers[this.show1[i].name],
                  type: this.show1[i].type,
                });
              }
            }
          }
          this.listAnswer = r;
        })
        .catch((error) => {
          this.loadingForm = false;
          console.log(error);
        });
    },

    updateStatus(status) {
      var data = {
        user_id: this.info.user_id,
        status_id: status,
      };
      this.loadingAll = true;
      Api.RetirementVoluntary()
        .updateForms(this.token, data)
        .then((res) => {
          this.message.dialog = true;
          this.message.sms = res.data.message;
          this.message.title = "Excelente";
          this.message.type = "success";
          this.message.redirect = "";
          this.message.params = null;
          if (res.data.cod == 0) {
            this.message.title = "Excelente";
            this.message.type = "success";
            this.getReport();
            this.modal = false;
          } else {
            this.message.title = "¡Oh no!";
            this.message.type = "error";
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },

    getInfo(data) {
      this.modal = true;
      this.info = data;
      this.show1 = [];
      this.listAnswer = [];
      this.getForms();
    },
    getProfile() {
      Api.MarginCommisions()
        .getProfile(this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listProfile = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");

      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getReport() {
      this.items = [];
      this.loadingAll = true;
      var data = {
        status_id: this.statusId,
        profile_id: this.profileId,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      Api.RetirementVoluntary()
        .getResign(this.token, data)
        .then((res) => {
          this.items = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    getColorStatus(status) {
      var color = this.color(status);
      return color;
    },
    toTitleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
    momentFormat(date) {
      return moment(date).format("DD/MM/YYYY, h:mm a");
    },
  },
  created() {
    this.getToken();
  },
  mounted() {
    this.getProfile();
    this.getReport();
  },
};
</script>
<style>
@import "./../../assets/css/main.less";

.report-voluntary__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 I !important;
}
tr,
th,
span .report-voluntary__table .text-start .sortable {
  font-size: 16px !important;
}

.report-voluntary__card {
  border-radius: 15px !important;
}

.report-voluntary__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.report-voluntary__other_title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.report-voluntary__txt_modal {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #353535;
}

.report-voluntary__txt_modal_small {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #353535;
}

.report-voluntary__txt_modal_ligth {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: #626262;
}

.report-voluntary__header {
  color: #353535 !important;
}

.report-retirement__button:hover {
  background-color: #324ca1 !important;
}
</style>
